import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RemoteServiceService {

  baseUrl = "http://develop.innova-cube.com/pda-tigo/"; //+++ Servidor API - Desarrollo
  //baseUrl = "https://pdaweb-stg-vpc-eks.tigocloud.net/"; //+++ Servidor API - Produccion
  //baseUrl = window.location.origin+"/";  //++environment.apiEndpoint;
  clientService = "frontend-client";
  authKey = "pdatigorestapi";


  constructor(private http: HttpClient) { }

  setHeaders()
  {

  }

  login(username:string, password:string): Observable<any>
  {

    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'PDATIGO-INNOVA-API-KEY': this.authKey
    });

    return this.http.post(this.baseUrl+"auth/login",{username:username, password:password},{ headers: headers });

  }

  auth(): any
  {
    if(localStorage.getItem("token"))
    {

      let estado = this.checkAuth().subscribe(result => {});
        return true;
      //else
        //return false;
    }
    else
    {
      //return {"statusRequest": "NO-TOKEN"};
      return false;
    }

  }

  checkAuth(): Observable<any>
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });

    return  this.http.post(this.baseUrl+"auth/auth",{},{ headers: headers });
  }

  logout()
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });

    return  this.http.post(this.baseUrl+"auth/logout",{},{ headers: headers });
  }

  //+++++++++ Para la Recuperaciones
  verificarLinea(linea, ci, fechaNacimiento, nombre): Observable<any>
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });

    return  this.http.post(this.baseUrl+"recuperacion/verificacionLinea",{linea:linea, ci:ci, fechaNacimiento:fechaNacimiento, nombre:nombre, idrecuperacion: localStorage.getItem("idRecuperacion")},{ headers: headers });
  }

  //++++++ Para subir una capturar
  upload(imgBase64, infoImagen): Observable<any>
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });


    return  this.http.post(this.baseUrl+"recuperacion/uploadedFile",{image: imgBase64, infoImagen:infoImagen, idrecuperacion: localStorage.getItem("idRecuperacion")},{ headers: headers });
  }

  //+++++++ Para crear la instanacia de la Recuperaciones
  crearRecuperacion(): Observable<any>
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });

    return  this.http.post(this.baseUrl+"recuperacion/crearRecuperacion",{},{ headers: headers });
  }


  changeSIM(recuperacion): Observable<any>
  {
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Client-Service': this.clientService,
    'User-ID': localStorage.getItem("users_id"),
    'PDATIGO-INNOVA-API-KEY': localStorage.getItem("token")
    });

    return  this.http.post(this.baseUrl+"recuperacion/changeSIM",{recuperacion:recuperacion},{ headers: headers });
  }

}
