import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';

import { RemoteServiceService } from '../remote-service.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

//  isAuth:boolean = false;

  constructor(private remoteService: RemoteServiceService, private _router: Router, public alertController: AlertController) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, remoteServiceService:RemoteServiceService): Observable<boolean> | Promise<boolean> | boolean
  {
    return this.remoteService.checkAuth().pipe(
        map(data => {

          if (data.statusRequest !="UPDATED-TOKEN") {
            this._router.navigate(['/']);
            localStorage.clear();
            return false;
          }

          return !!data;
        },
        (err) => this.showAlert("Error: No se tiene respuesta del servidor")
      )
      );



  }

  //+++++ Funcion para mostrar la ventana de alerta
  async showAlert(texto:string) {
    const alert = await this.alertController.create({
      header: 'Alerta',
      subHeader: '',
      message: texto,
      buttons: ['OK']
    });

    await alert.present();
  }
}
