import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuard] },
  { path: 'intro', loadChildren: './recuperacion/intro/intro.module#IntroPageModule', canActivate: [AuthGuard] },
  { path: 'confirmacion', loadChildren: './recuperacion/confirmacion/confirmacion.module#ConfirmacionPageModule', canActivate: [AuthGuard] },
  { path: 'incorrecto', loadChildren: './recuperacion/incorrecto/incorrecto.module#IncorrectoPageModule', canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
